import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissoesCadastros } from '../models/auth-user.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CadastrosAuthService {
    acessarModuloCadastros$: Observable<boolean>;

    acessarFilial$: Observable<boolean>;
    acessarCentroDeCusto$: Observable<boolean>;
    acessarProjeto$: Observable<boolean>;
    acessarFornecedor$: Observable<boolean>;
    acessarColaborador$: Observable<boolean>;
    acessarProduto$: Observable<boolean>;
    acessarProdutoTabelas$: Observable<boolean>;
    acessarCliente$: Observable<boolean>;
    acessarOutros$: Observable<boolean>;
    acessarCadastrosInterno$: Observable<boolean>;
    acessarRelatoriosCadastros$: Observable<boolean>;


    constructor(public authService: AuthService) {
        this.acessarCadastrosInterno$ = this.authService.loginInterno$.pipe(
            map((r: boolean) => r)
        );

        this.acessarFilial$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) => a || c.filiais || c.consultarFiliais
        );

        this.acessarCentroDeCusto$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) => {
                return a || c.centrosDeCusto || c.consultarCentroDeCusto;
            }
        );

        this.acessarProduto$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) =>
                a || c.produtos || c.consultarProdutos
        );

        this.acessarProdutoTabelas$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) => a || c.produtos
        );

        this.acessarFornecedor$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) =>
                a || c.fornecedores || c.consultarFornecedores
        );

        this.acessarProjeto$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) =>
                a || c.projetos || c.consultarProjetos
        );

        this.acessarCliente$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) => {
                return a || c.clientes || c.consultarClientes;
            }
        );

        this.acessarOutros$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) => {
                return a || c.outros || c.consultarOutros;
            }
        );

        this.acessarColaborador$ = combineLatest(
            this.authService.administrador$,
            this.authService.permissoesCadastros$,
            (a, c: PermissoesCadastros) =>
                a || c.colaboradores || c.consultarColaboradores
        );

        this.acessarModuloCadastros$ = combineLatest(
            this.authService.administrador$,
            this.acessarFilial$,
            this.acessarCentroDeCusto$,
            this.acessarProjeto$,
            this.acessarFornecedor$,
            this.acessarProduto$,
            this.acessarColaborador$,
            this.acessarCliente$,
            this.acessarOutros$,
            (fil, cc, proj, fornec, prod, colab, cli, outros) => {
                return (
                    fil ||
                    cc ||
                    proj ||
                    fornec ||
                    prod ||
                    colab ||
                    cli ||
                    outros
                );
            }
        );

        this.acessarRelatoriosCadastros$ = combineLatest(
            this.acessarFornecedor$,
            this.acessarProduto$,
            (f, p) => {
                return f || p;
            }
        );
    }
}
