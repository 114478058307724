import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';

import { StoreService } from '../../store/app-store.service';
import { AppState } from '../../store/app.reducer';
import {
    AuthUser,
    PermissoesCadastros,
    PermissoesCompras,
    PermissoesCrm,
    PermissoesEstoque,
    PermissoesFinanceiro,
    PermissoesSistema,
    PermissoesVendas,
} from '../models/auth-user.model';
import * as act from './auth.actions';
import { AUTH_STORE, AuthState } from './auth.state';

@Injectable()
export class AuthStoreService extends StoreService {
    protected readonly STATE = AUTH_STORE;

    constructor(protected store: Store<AppState>) {
        super();
    }

    dispatchAutenticarLogin(email: string, senha: string) {
        this.dispatchAction(new act.AutenticarLoginAction({ email, senha }));
    }

    dispatchAutenticarLoginInterno(
        email: string,
        senha: string,
        codigoDaEmpresa: number,
        codigoDoUsuario: string
    ) {
        this.dispatchAction(
            new act.AutenticarLoginInternoAction({
                email,
                senha,
                codigoDaEmpresa,
                codigoDoUsuario,
            })
        );
    }

    dispatchAutenticarLoginInternoAdmin(
        email: string,
        senha: string,
        codigoDaEmpresa: number
    ) {
        this.dispatchAction(
            new act.AutenticarLoginInternoAdminAction({
                email,
                senha,
                codigoDaEmpresa,
            })
        );
    }

    dispatchAutenticarLoginCliente(id: string, key: string, username: string) {
        this.dispatchAction(
            new act.AutenticarLoginClienteAction({ id, key, username })
        );
    }

    dispatchAutenticarLoginBaseOTK(email: string, senha: string) {
        this.dispatchAction(
            new act.AutenticarLoginBaseOTKAction({ email, senha })
        );
    }

    dispatchAutenticarLoginBaseAfixGraf(email: string, senha: string) {
        this.dispatchAction(
            new act.AutenticarLoginBaseAfixGrafAction({ email, senha })
        );
    }

    dispatchAutenticarLoginBradesco(upn: string, senha: string) {
        this.dispatchAction(
            new act.AutenticarLoginBradescoAction({ upn, senha })
        );
    }

    dispatchRefreshToken(token: any) {
        this.dispatchAction(new act.RefreshTokenAction(token));
    }

    dispatchRecuperarLogin(token: any) {
        this.dispatchAction(new act.RecuperarLoginAction(token));
    }

    dispatchEfetuarLogout() {
        this.dispatchAction(new act.EfetuarLogoutAction());
    }

    dispatchRegistrarLogin() {
        this.dispatchAction(new act.RegistrarLoginAction());
    }

    dispatchRegistrarLogout() {
        this.dispatchAction(new act.RegistrarLogoutAction());
    }

    dispatchEnviarNovaSenha(email: string) {
        this.dispatchAction(new act.EnviarNovaSenhaAction(email));
    }

    dispatchCriarNovaConta(obj: any) {
        this.dispatchAction(new act.CriarNovaContaAction(obj));
    }

    dispatchConfirmarEmailSenha(
        email: string,
        novaSenha: string,
        token: string
    ) {
        this.dispatchAction(
            new act.ConfirmarEmailSenhaAction({ email, novaSenha, token })
        );
    }

    dispatchRedefinirSenha(email: string, novaSenha: string, token: string) {
        this.dispatchAction(
            new act.RedefinirSenhaAction({ email, novaSenha, token })
        );
    }

    dispatchLimparAction() {
        this.dispatchAction(new act.LimparAction());
    }

    getUser() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined)
        );
    }

    getUserInternal() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state),
            filter((state: AuthState) => state.isProcessing == false),
            map((state: AuthState) => state.user),
            map((usr: AuthUser) => {
                if (usr && usr.loginInterno) {
                    return usr;
                }
                return null;
            })
        );
    }

    getUserIsAuthenticated() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state),
            filter((state: AuthState) => state.isProcessing == false),
            map((state: AuthState) => state.user),
            map((usr: AuthUser) => {
                if (!usr) {
                    return false;
                }

                return usr.codigoDaEmpresa > 0 && usr.autenticado;
            })
        );
    }

    getIsProcessing() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.isProcessing),
            filter((r) => r == true || r == false)
        );
    }

    getModuloCadastros() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => {
                if (!usr || !usr.cadastros) {
                    return false;
                }
                return (
                    usr.administrador ||
                    usr.cadastros.produtos ||
                    usr.cadastros.fornecedores ||
                    usr.cadastros.projetos ||
                    usr.cadastros.clientes ||
                    usr.cadastros.colaboradores ||
                    usr.cadastros.filiais ||
                    usr.cadastros.outros ||
                    usr.cadastros.centrosDeCusto ||
                    usr.cadastros.consultarProdutos ||
                    usr.cadastros.consultarFornecedores ||
                    usr.cadastros.consultarProdutos ||
                    usr.cadastros.consultarFornecedores ||
                    usr.cadastros.consultarProjetos ||
                    usr.cadastros.consultarCentroDeCusto ||
                    usr.cadastros.consultarClientes ||
                    usr.cadastros.consultarColaboradores ||
                    usr.cadastros.consultarFiliais ||
                    usr.cadastros.consultarOutros
                );
            })
        );
    }

    getPermissoesCadastros(): Observable<PermissoesCadastros> {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            // tap(usr => console.log("PERMISSOES CADASTROS =>", usr)),
            map((usr: AuthUser) => usr.cadastros)
        );
    }

    getModuloCrm() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => {
                if (!usr || !usr.crm) {
                    return false;
                }
                return usr.crm.responsavel;
            })
        );
    }

    getPermissoesCrm(): Observable<PermissoesCrm> {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => usr.crm)
        );
    }

    getModuloVendas() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => {
                if (!usr || !usr.vendas) {
                    return false;
                }
                return (
                    usr.vendas.responsavel ||
                    usr.vendas.vendasFatura ||
                    usr.vendas.vendasOrdem ||
                    usr.vendas.vendasNfse ||
                    usr.vendas.vendasNfe
                );
            })
        );
    }

    getPermissoesVendas(): Observable<PermissoesVendas> {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => usr.vendas)
        );
    }

    getModuloFinanceiro() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => {
                if (!usr || !usr.financeiro) {
                    return false;
                }
                return (
                    usr.financeiro.responsavel ||
                    usr.financeiro.cadastrarPrevisao ||
                    usr.financeiro.cadastrarCP ||
                    usr.financeiro.cadastrarCR ||
                    usr.financeiro.cadastrarCaixaBancos ||
                    usr.financeiro.consultarCP ||
                    usr.financeiro.consultarCR
                );
            })
        );
    }

    getPermissoesFinanceiro(): Observable<PermissoesFinanceiro> {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => usr.financeiro)
        );
    }

    getModuloCompras() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => {
                if (!usr || !usr.compras) {
                    return false;
                }
                return (
                    usr.compras.responsavel ||
                    usr.compras.requisitante ||
                    usr.compras.cadastrarOrdem ||
                    usr.compras.cadastrarOrdemLote ||
                    usr.compras.cadastrarPedido ||
                    usr.compras.aprovadorRequisicao ||
                    usr.compras.aprovadorOrdem ||
                    usr.compras.aprovadorOrdemLote ||
                    usr.compras.aprovadorPedido ||
                    usr.compras.processarRequisicao ||
                    usr.compras.processarOrdem ||
                    usr.compras.processarOrdemLote ||
                    usr.compras.processarPedido ||
                    usr.compras.processarRecebimento ||
                    usr.compras.gerarCP ||
                    usr.compras.planejamentoOrcamentario ||
                    usr.compras.sugestao ||
                    usr.compras.selecionarCotacoes
                );
            })
        );
    }

    getPermissoesCompras(): Observable<PermissoesCompras> {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => usr.compras)
        );
    }

    getModuloEstoque() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => {
                if (!usr || !usr.estoque) {
                    return false;
                }
                return (
                    usr.estoque.responsavel ||
                    usr.estoque.aprovador ||
                    usr.estoque.requisitante ||
                    usr.estoque.processar ||
                    usr.estoque.processarExpedicao ||
                    usr.estoque.entrada ||
                    usr.estoque.saida ||
                    usr.estoque.transferencia ||
                    usr.estoque.ajuste ||
                    usr.estoque.consultarSaldos ||
                    usr.estoque.consultarExpedicao
                );
            })
        );
    }

    getModuloEstoqueMovimentacoes() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => {
                if (!usr || !usr.estoque) {
                    return false;
                }
                return (
                    usr.estoque.responsavel ||
                    usr.estoque.entrada ||
                    usr.estoque.saida ||
                    usr.estoque.transferencia ||
                    usr.estoque.ajuste
                );
            })
        );
    }

    getPermissoesEstoque(): Observable<PermissoesEstoque> {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => usr.estoque)
        );
    }

    getModuloSistema() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => {
                if (!usr || !usr.sistema) {
                    return false;
                }
                return (
                    (usr.administrador || usr.sistema.exportacao) &&
                    usr.autenticado
                );
            })
        );
    }

    getPermissoesSistema(): Observable<PermissoesSistema> {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.user),
            filter((usr: AuthUser) => usr != null && usr != undefined),
            map((usr: AuthUser) => usr.sistema)
        );
    }

    getSuccess() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.success),
            filter((r) => r != null && r != undefined)
        );
    }

    getWarning() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.warning),
            filter((r) => r && r.titulo != null && r.mensagem != null)
        );
    }

    getError() {
        return this.storeSelectFeatureState().pipe(
            select((state: AuthState) => state.error),
            filter((r) => r)
        );
    }
}
