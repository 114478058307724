export enum StateModeEnum {
    undefined = 0,

    insert = 1,
    edit = 2,
    browse = 4,
    selected = 5,
    modified = 6,
    removed = 7,
}

export enum UnidadeMedidaEnum {
    QTDE = 'QTDE',
    METRO = 'M',
    CENTIMETRO = 'CM',
}

export enum GridNameEnum {
    OTKWEB_AGENCIA = 'OTKWEB_AGENCIA',
    OTKWEB_AJUSTE_ESTOQUE = 'OTKWEB_AJUSTE_ESTOQUE',
    OTKWEB_ALMOXARIFADO = 'OTKWEB_ALMOXARIFADO',
    OTKWEB_BANCO = 'OTKWEB_BANCO',
    OTKWEB_BASE_FORNECEDORES = 'OTKWEB_BASE_FORNECEDORES',
    OTKWEB_CAIXA_BANCOS = 'OTKWEB_CAIXA_BANCOS',
    OTKWEB_CARTEIRA = 'OTKWEB_CARTEIRA',
    OTKWEB_CATEGORIA_CLIENTE = 'OTKWEB_CATEGORIA_CLIENTE',
    OTKWEB_CENTRO_DE_CUSTO = 'OTKWEB_CENTRO_DE_CUSTO',
    OTKWEB_CENTRO_DE_CUSTO_GRUPO = 'OTKWEB_CENTRO_DE_CUSTO_GRUPO',
    OTKWEB_CFOP = 'OTKWEB_CFOP',
    OTKWEB_CLASSIFICACAO_AUXILIAR = 'OTKWEB_CLASSIFICACAO_AUXILIAR',
    OTKWEB_CLASSIFICACAO_AUXILIAR_ESTOQUE = 'OTKWEB_CLASSIFICACAO_AUXILIAR_ESTOQUE',
    OTKWEB_CLIENTE_ARQUIVOS_ANEXADOS = 'OTKWEB_CLIENTE_ARQUIVOS_ANEXADOS',
    OTKWEB_CODIGO_DE_SERVICO = 'OTKWEB_CODIGO_DE_SERVICO',
    OTKWEB_COLABORADOR_USUARIO = 'OTKWEB_COLABORADOR_USUARIO',
    OTKWEB_COLABORADOR_REQUISITANTE = 'OTKWEB_COLABORADOR_REQUISITANTE',
    OTKWEB_COLABORADOR_GRUPO = 'OTKWEB_COLABORADOR_GRUPO',
    OTKWEB_CONDICAO_DE_PAGAMENTO_PARCELAS = 'OTKWEB_CONDICAO_DE_PAGAMENTO_PARCELAS',
    OTKWEB_CONTA_CORRENTE = 'OTKWEB_CONTA_CORRENTE',
    OTKWEB_CONTAS_PAGAR_V2 = 'OTKWEB_CONTAS_PAGAR_V1',
    OTKWEB_CONTAS_PAGAR_BX_V2 = 'OTKWEB_CONTAS_PAGAR_BX_V1',
    OTKWEB_CONTAS_PAGAR_RATEIOS = 'OTKWEB_CONTAS_PAGAR_RATEIOS',
    OTKWEB_CONTAS_PAGAR_PESQUISAR = 'OTKWEB_CONTAS_PAGAR_PESQUISAR',
    OTKWEB_CONTAS_RECEBER = 'OTKWEB_CONTAS_RECEBER',

    OTKWEB_CONTAS_RECEBER_BX = 'OTKWEB_CONTAS_RECEBER_BX',

    OTKWEB_CONTAS_RECEBER_PREJUIZOS = 'OTKWEB_CONTAS_RECEBER_PREJUIZOS',
    OTKWEB_CONTAS_RECEBER_PESQUISAR = 'OTKWEB_CONTAS_RECEBER_PESQUISAR',
    OTKWEB_CONTAS_PAGAR_PESQUISAR_V2 = 'OTKWEB_CONTAS_PAGAR_PESQUISAR_V1',
    OTKWEB_CONTAS_RECEBER_V2 = 'OTKWEB_CONTAS_RECEBER',
    OTKWEB_CONTAS_RECEBER_RATEIOS = 'OTKWEB_CONTAS_RECEBER_RATEIOS',
    OTKWEB_CONTAS_RECEBER_BX_V2 = 'OTKWEB_CONTAS_RECEBER_BX_V1',
    OTKWEB_CONTAS_RECEBER_PREJUIZOS_V2 = 'OTKWEB_CONTAS_RECEBER_PREJUIZOS_V1',
    OTKWEB_CONTAS_RECEBER_PESQUISAR_V2 = 'OTKWEB_CONTAS_RECEBER_PESQUISAR_V1',
    OTKWEB_CONTRATO_DE_FORNECIMENTO = 'OTKWEB_CONTRATO_DE_FORNECIMENTO',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_TIPO = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_TPO',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_ATIVOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_ATIVOS',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_INATIVOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_INATIVOS',
    OTKWEB_COTACAO_SELECAO_FORNECEDORES = 'OTKWEB_COTACAO_SELECAO_FORNECEDORES',
    OTKWEB_COTACOES_FORNECEDOR = 'OTKWEB_COTACOES_FORNECEDOR',
    OTKWEB_COTACOES_ITENS_GRID = 'OTKWEB_COTACOES_ITENS_GRID_V1',
    OTKWEB_CR_CP_ARQUIVOS_ANEXADOS = 'OTKWEB_CR_CP_ARQUIVOS_ANEXADOS',
    OTKWEB_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS = 'OTKWEB_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS',
    OTKWEB_CRM_CLIENTES = 'OTKWEB_CRM_CLIENTES',
    OTKWEB_CRM_CLIENTES_CONTAS_RECEBER = 'OTKWEB_CRM_CLIENTES_CONTAS_RECEBER',
    OTKWEB_CRM_CLIENTES_CONTATO = 'OTKWEB_CRM_CLIENTES_CONTATO',
    OTKWEB_CRM_CLIENTES_PESQUISAR = 'OTKWEB_CRM_CLIENTES_PESQUISAR',
    OTKWEB_CRM_TIPO_CLIENTES = 'OTKWEB_CRM_TIPO_CLIENTES',
    OTKWEB_CRM_SITUACAO_CLIENTES = 'OTKWEB_CRM_SITUACAO_CLIENTES',
    OTKWEB_CUSTO_ABC = 'OTKWEB_CUSTO_ABC',
    OTKWEB_DUPLICATA_COMPRAS = 'OTKWEB_DUPLICATA_COMPRAS',
    OTKWEB_DUPLICATA_VENDAS = 'OTKWEB_DUPLICATA_VENDAS',
    OTKWEB_DUPLICATA_HISTORICO_CP = 'OTKWEB_DUPLICATA_HISTORICO_CP',
    OTKWEB_DUPLICATA_HISTORICO_CR = 'OTKWEB_DUPLICATA_HISTORICO_CR',
    OTKWEB_ENDERECO_DE_ENTREGA = 'OTKWEB_ENDERECO_DE_ENTREGA',
    OTKWEB_ENTRADA_DE_ESTOQUE = 'OTKWEB_ENTRADA_DE_ESTOQUE',
    OTKWEB_ENTRADA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_ENTRADA_DE_ESTOQUE_PESQUISAR',
    OTKWEB_ENTRADA_LOTE_ABERTAS = 'OTKWEB_ENTRADA_LOTE_ABERTAS',
    OTKWEB_ENTRADA_LOTE_ENCERRADAS = 'OTKWEB_ENTRADA_LOTE_ABERTAS',
    OTKWEB_ENTRADA_LOTE_ITENS = 'OTKWEB_ENTRADA_LOTE_ITENS',
    OTKWEB_EXPEDICAO_CANCELADOS = 'OTKWEB_EXPEDICAO_CANCELADOS',
    OTKWEB_EXPEDICAO_DE_ESTOQUE_PESQUISAR_V1 = 'OTKWEB_EXPEDICAO_DE_ESTOQUE_PESQUISAR_V1',
    OTKWEB_EXPEDICAO_PENDENTES_V1 = 'OTKWEB_EXPEDICAO_PENDENTES_V1',
    OTKWEB_EXPEDICAO_EM_PROCESSAMENTO = 'OTKWEB_EXPEDICAO_EM_PROCESSAMENTO',
    OTKWEB_EXPEDICAO_PROCESSADOS = 'OTKWEB_EXPEDICAO_PROCESSADOS',
    OTKWEB_EXPORTACAO_DE_RELATORIO = 'OTKWEB_EXPORTACAO_DE_RELATORIO',

    OTKWEB_EXPEDICAO_POR_REQUISICAO = 'OTKWEB_EXPEDICAO_POR_REQUISICAO',
    OTKWEB_EXPEDICAO_POR_REQUISICAO_DETALHE = 'OTKWEB_EXPEDICAO_POR_REQUISICAO_DETALHE',

    OTKWEB_FATURA_ABERTAS = 'OTKWEB_FATURA_ABERTAS',
    OTKWEB_FATURA_CANCELADAS = 'OTKWEB_FATURA_CANCELADAS',
    OTKWEB_FATURA_PROCESSADAS = 'OTKWEB_FATURA_PROCESSADAS',
    OTKWEB_FATURA_SERVICOS = 'OTKWEB_FATURA_SERVICOS',

    OTKWEB_FILIAL = 'OTKWEB_FILIAL',
    OTKWEB_FINANCEIRO_CAIXA_BANCOS = 'OTKWEB_FINANCEIRO_CAIXA_BANCOS',
    OTKWEB_FINANCEIRO_CAIXA_CP = 'OTKWEB_FINANCEIRO_CAIXA_CP',
    OTKWEB_FINANCEIRO_CAIXA_CR = 'OTKWEB_FINANCEIRO_CAIXA_CR',
    OTKWEB_FINANCEIRO_CAIXA_DESDOBRAMENTOS = 'OTKWEB_FINANCEIRO_CAIXA_DESDOBRAMENTOS',
    OTKWEB_CONDICAO_DE_PAGAMENTO = 'OTKWEB_CONDICAO_DE_PAGAMENTO',
    OTKWEB_MAPEAMENTO_DE_RATEIO = 'OTKWEB_MAPEAMENTO_DE_RATEIO',
    OTKWEB_FORNECEDOR = 'OTKWEB_FORNECEDOR',
    OTKWEB_FORNECEDOR_ARQUIVOS_ANEXADOS = 'OTKWEB_FORNECEDOR_ARQUIVOS_ANEXADOS',
    OTKWEB_FORNECEDOR_CONTATO = 'OTKWEB_FORNECEDOR_CONTATO',
    OTKWEB_FORNECEDOR_COTACOES = 'OTKWEB_FORNECEDOR_COTACOES',
    OTKWEB_FORNECEDOR_CP = 'OTKWEB_FORNECEDOR_CP',
    OTKWEB_FORNECEDOR_PEDIDO_DE_COMPRA = 'OTKWEB_FORNECEDOR_PEDIDO_DE_COMPRA',
    OTKWEB_FORNECEDOR_PESQUISAR = 'OTKWEB_FORNECEDOR_PESQUISAR',
    OTKWEB_SEGMENTO = 'OTKWEB_SEGMENTO',
    OTKWEB_RAMO_DE_ATIVIDADE = 'OTKWEB_RAMO_DE_ATIVIDADE',
    OTKWEB_FORNECEDOR_TIPO = 'OTKWEB_FORNECEDOR_TIPO',
    OTKWEB_GRUPO_LANCAMENTO = 'OTKWEB_GRUPO_LANCAMENTO',
    OTKWEB_ITEM_LANCAMENTO = 'OTKWEB_ITEM_LANCAMENTO',
    OTKWEB_MAPEAMENTO_PRODUTO = 'OTKWEB_MAPEAMENTO_PRODUTO',
    OTKWEB_MOTIVO_DA_COMPRA = 'OTKWEB_MOTIVO_DA_COMPRA',
    OTKWEB_MOTIVO_REQUISICAO = 'OTKWEB_MOTIVO_REQUISICAO',
    OTKWEB_NCM = 'OTKWEB_NCM',
    OTKWEB_NIVEL_DE_APROVACAO = 'OTKWEB_NIVEL_DE_APROVACAO',
    OTKWEB_NIVEL_DE_APROVACAO_ESTOQUE = 'OTKWEB_NIVEL_DE_APROVACAO_ESTOQUE',
    OTKWEB_NOTA_FISCAL_PRODUTO_CANCELADAS = 'OTKWEB_NOTA_FISCAL_PRODUTO_CANCELADAS',
    OTKWEB_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO = 'OTKWEB_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO',
    OTKWEB_NOTA_FISCAL_PRODUTO_EMITIDAS = 'OTKWEB_NOTA_FISCAL_PRODUTO_EMITIDAS',
    OTKWEB_NOTA_FISCAL_PRODUTO_ITENS = 'OTKWEB_NOTA_FISCAL_PRODUTO_ITENS',
    OTKWEB_NOTA_FISCAL_PRODUTO_SERVICOS = 'OTKWEB_NOTA_FISCAL_PRODUTO_SERVICOS',
    OTKWEB_NOTA_FISCAL_PRODUTO_PENDENTES = 'OTKWEB_NOTA_FISCAL_PRODUTO_PENDENTES',
    OTKWEB_NOTA_FISCAL_SERVICO_CANCELADAS = 'OTKWEB_NOTA_FISCAL_SERVICO_CANCELADAS',
    OTKWEB_NOTA_FISCAL_SERVICO_EMITIDAS = 'OTKWEB_NOTA_FISCAL_SERVICO_EMITIDAS',
    OTKWEB_NOTA_FISCAL_SERVICO_PENDENTES = 'OTKWEB_NOTA_FISCAL_SERVICO_PENDENTES',
    OTKWEB_ORDEM_DE_COMPRA_ABERTAS_V1 = 'OTKWEB_ORDEM_DE_COMPRA_ABERTAS_V1',
    OTKWEB_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS = 'OTKWEB_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS',
    OTKWEB_ORDEM_DE_COMPRA_COTACOES = 'OTKWEB_ORDEM_DE_COMPRA_COTACOES_V1',
    OTKWEB_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO = 'OTKWEB_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO',
    OTKWEB_ORDEM_DE_COMPRA_ENCERRADAS_V1 = 'OTKWEB_ORDEM_DE_COMPRA_ENCERRADAS_V1',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ABERTAS_V1 = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ABERTAS_  V1',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ENCERRADAS = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ENCERRADAS',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ITENS = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ITENS',
    OTKWEB_ORDEM_DE_COMPRA_PENDENTE_APROVACAO = 'OTKWEB_ORDEM_DE_COMPRA_PENDENTE_APROVACAO',
    OTKWEB_ORDEM_DE_COMPRA_PESQUISAR_V1 = 'OTKWEB_ORDEM_DE_COMPRA_PESQUISAR_V1',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_V1',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_RATEIO = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_RATEIO',
    OTKWEB_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES = 'OTKWEB_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES',
    OTKWEB_ORDEM_PRODUTO = 'OTKWEB_ORDEM_PRODUTO',
    OTKWEB_ORDEM_SERVICOS_ABERTAS = 'OTKWEB_ORDEM_SERVICOS_ABERTAS',
    OTKWEB_ORDEM_SERVICOS_CANCELADAS = 'OTKWEB_ORDEM_SERVICOS_CANCELADAS',
    OTKWEB_ORDEM_SERVICOS_PROCESSADAS = 'OTKWEB_ORDEM_SERVICOS_PROCESSADAS',
    OTKWEB_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL = 'OTKWEB_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL',
    OTKWEB_PAIS = 'OTKWEB_PAIS',
    OTKWEB_PARCELAS_PEDIDO_DE_COMPRA = 'OTKWEB_PARCELAS_PEDIDO_DE_COMPRA',
    OTKWEB_PEDIDO_DE_VENDA_ABERTAS = 'OTKWEB_PEDIDO_DE_VENDA_ABERTAS',
    OTKWEB_PEDIDO_DE_VENDA_CANCELADAS = 'OTKWEB_PEDIDO_DE_VENDA_CANCELADAS',
    OTKWEB_PEDIDO_DE_VENDA_PROCESSADAS = 'OTKWEB_PEDIDO_DE_VENDA_PROCESSADAS',
    OTKWEB_PEDIDO_DE_VENDA_PRODUTO_ITENS = 'OTKWEB_PEDIDO_DE_VENDA_PRODUTO_ITENS',
    OTKWEB_PEDIDO_DE_COMPRA_ABERTAS_V1 = 'OTKWEB_PEDIDO_DE_COMPRA_ABERTAS_V1',
    OTKWEB_PEDIDO_DE_COMPRA_ENCERRADAS_V1 = 'OTKWEB_PEDIDO_DE_COMPRA_ENCERRADAS_V1',
    OTKWEB_PEDIDO_DE_COMPRA_ITENS = 'OTKWEB_PEDIDO_DE_COMPRA_ITENS',
    OTKWEB_PEDIDO_DE_COMPRA_PESQUISAR_V1 = 'OTKWEB_PEDIDO_DE_COMPRA_PESQUISAR_V1',
    OTKWEB_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL = 'OTKWEB_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL',
    OTKWEB_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO = 'OTKWEB_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO',
    OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS = 'OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS',
    OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL = 'OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL',
    OTKWEB_PLANO_DE_CONTAS = 'OTKWEB_PLANO_DE_CONTAS',
    OTKWEB_PLANEJAMENTO_ORCAMENTARIO = 'OTKWEB_PLANEJAMENTO_ORCAMENTARIO',
    OTKWEB_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS = 'OTKWEB_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS',
    OTKWEB_PREVISAO_CONTAS_PAGAR_V1 = 'OTKWEB_PREVISAO_CONTAS_PAGAR',
    OTKWEB_PREVISAO_CONTAS_PAGAR_RATEIOS = 'OTKWEB_PREVISAO_CONTAS_PAGAR_RATEIOS',

    OTKWEB_PREVISAO_CONTAS_RECEBER = 'OTKWEB_PREVISAO_CONTAS_RECEBER',
    OTKWEB_PREVISAO_CONTAS_RECEBER_V1 = 'OTKWEB_PREVISAO_CONTAS_RECEBER',
    OTKWEB_PREVISAO_CONTAS_RECEBER_RATEIOS = 'OTKWEB_PREVISAO_CONTAS_RECEBER_RATEIOS',
    OTKWEB_PREVISAO_FATURAMENTO_SERVICO = 'OTKWEB_PREVISAO_FATURAMENTO_SERVICO',
    OTKWEB_PREVISAO_FATURAMENTO_SERVICO_ITENS = 'OTKWEB_PREVISAO_FATURAMENTO_SERVICO_ITENS',
    OTKWEB_PREVISAO_FATURAMENTO_FATURA = 'OTKWEB_PREVISAO_FATURAMENTO_FATURA',
    OTKWEB_PREVISAO_FATURAMENTO_FATURA_SERVICOS = 'OTKWEB_PREVISAO_FATURAMENTO_FATURA_SERVICOS',
    OTKWEB_PREVISAO_HISTORICO_CP = 'OTKWEB_PREVISAO_HISTORICO_CP',
    OTKWEB_PREVISAO_HISTORICO_CR = 'OTKWEB_PREVISAO_HISTORICO_CR',
    OTKWEB_PRODUTO = 'OTKWEB_PRODUTO',
    OTKWEB_PRODUTO_ARQUIVOS_ANEXADOS = 'OTKWEB_PRODUTO_ARQUIVOS_ANEXADOS',
    OTKWEB_PRODUTO_CATEGORIA = 'OTKWEB_PRODUTO_CATEGORIA',
    OTKWEB_PRODUTO_ESTOQUE = 'OTKWEB_PRODUTO_ESTOQUE',
    OTKWEB_PRODUTO_ESTOQUE_ENDERECAMENTOS = 'OTKWEB_PRODUTO_ESTOQUE_ENDERECAMENTOS',
    OTKWEB_PRODUTO_GRUPO = 'OTKWEB_PRODUTO_GRUPO',
    OTKWEB_PRODUTO_MOVIMENTACAO = 'OTKWEB_PRODUTO_MOVIMENTACAO',
    OTKWEB_PRODUTO_PEDIDOS_COMPRAS = 'OTKWEB_PRODUTO_PEDIDOS_COMPRAS',
    OTKWEB_PRODUTO_PESQUISAR = 'OTKWEB_PRODUTO_PESQUISAR',
    OTKWEB_PRODUTO_SERIES_LOTE = 'OTKWEB_PRODUTO_SERIES_LOTE',
    OTKWEB_PRODUTO_SUGESTAO_COMPRAS = 'OTKWEB_PRODUTO_SUGESTAO_COMPRAS',

    OTKWEB_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA = 'OTKWEB_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA',

    OTKWEB_PROJETO = 'OTKWEB_PROJETO',
    OTKWEB_PROJETO_ARQUIVOS_ANEXADOS = 'OTKWEB_PROJETO_ARQUIVOS_ANEXADOS',
    OTKWEB_PROJETO_MOVIMENTACAO = 'OTKWEB_PROJETO_MOVIMENTACAO',
    OTKWEB_PROJETO_PEDIDO_DE_COMPRA = 'OTKWEB_PROJETO_PEDIDO_DE_COMPRA',
    OTKWEB_PROJETO_CONTAS_PAGAR = 'OTKWEB_PROJETO_CONTAS_PAGAR',
    OTKWEB_PROJETO_CONTAS_RECEBER = 'OTKWEB_PROJETO_CONTAS_RECEBER',
    OTKWEB_RECEBIMENTO_DE_COMPRA_PESQUISAR = 'OTKWEB_RECEBIMENTO_DE_COMPRA_PESQUISAR',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_CANCELADOS = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_CANCELADOS',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_PENDENTES_V1 = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_PENDENTES_V1',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_PROCESSADOS = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_PROCESSADOS',
    OTKWEB_RECEBIMENTOS_POR_PEDIDO = 'OTKWEB_RECEBIMENTOS_POR_PEDIDO',
    OTKWEB_RECEBIMENTOS_POR_PEDIDO_DETALHE = 'OTKWEB_RECEBIMENTOS_POR_PEDIDO_DETALHE',
    OTKWEB_REGRA_DE_APROVACAO = 'OTKWEB_REGRA_DE_APROVACAO',
    OTKWEB_REGRA_DE_APROVACAO_OC = 'OTKWEB_REGRA_DE_APROVACAO_OC',
    OTKWEB_REGRA_DE_APROVACAO_OL = 'OTKWEB_REGRA_DE_APROVACAO_OL',
    OTKWEB_REGRA_DE_APROVACAO_PC = 'OTKWEB_REGRA_DE_APROVACAO_PC',
    OTKWEB_REGRA_DE_APROVACAO_RC = 'OTKWEB_REGRA_DE_APROVACAO_RC',
    OTKWEB_REQUISICAO_DE_COMPRAS_ABERTAS = 'OTKWEB_REQUISICAO_ABERTAS_DE_COMPRAS',
    OTKWEB_REQUISICAO_DE_COMPRAS_ITENS = 'OTKWEB_REQUISICAO_DE_COMPRAS_ITENS',
    OTKWEB_REQUISICAO_DE_COMPRAS_ENCERRADAS = 'OTKWEB_REQUISICAO_ENCERRADAS_DE_COMPRAS',
    OTKWEB_REQUISICAO_DE_COMPRAS_PESQUISAR = 'OTKWEB_REQUISICAO_DE_COMPRAS_PESQUISAR',
    OTKWEB_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS = 'OTKWEB_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS',
    OTKWEB_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO = 'OTKWEB_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ABERTAS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ABERTAS',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ENCERRADAS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ENCERRADAS',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS',
    OTKWEB_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS',
    OTKWEB_REQUISICAO_DE_ESTOQUE_PESQUISAR = 'OTKWEB_REQUISICAO_DE_ESTOQUE_PESQUISAR',
    OTKWEB_REQUISICAO_PRODUTO = 'OTKWEB_REQUISICAO_PRODUTO',
    OTKWEB_SAIDA_DE_ESTOQUE = 'OTKWEB_SAIDA_DE_ESTOQUE',
    OTKWEB_SAIDA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_SAIDA_DE_ESTOQUE_PESQUISAR',
    OTKWEB_SALDOS_EM_ESTOQUE = 'OTKWEB_SALDOS_EM_ESTOQUE',
    OTKWEB_SECAO = 'OTKWEB_SECAO',
    OTKWEB_SUGESTAO_DE_COMPRAS = 'OTKWEB_SUGESTAO_DE_COMPRAS',
    OTKWEB_TIPO_DE_COMPRA = 'OTKWEB_TIPO_DE_COMPRA',
    OTKWEB_TIPO_DE_DOCUMENTO = 'OTKWEB_TIPO_DE_DOCUMENTO',
    OTKWEB_TIPO_DE_PAGAMENTO = 'OTKWEB_TIPO_DE_PAGAMENTO',
    OTKWEB_TIPO_PRODUTO = 'OTKWEB_TIPO_PRODUTO',
    OTKWEB_TIPO_REQUISICAO = 'OTKWEB_TIPO_REQUISICAO',
    OTKWEB_TRANSFERENCIA_EM_LOTE_ESTOQUE = 'OTKWEB_TRANSFERENCIA_EM_LOTE',
    OTKWEB_TRANSFERENCIAS = 'OTKWEB_TRANSFERENCIAS',
    OTKWEB_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR',
    OTKWEB_UNIDADE_MEDIDA = 'OTKWEB_UNIDADE_MEDIDA',
    OTKWEB_VENDAS_FATURA_PESQUISAR = 'OTKWEB_VENDAS_FATURA_PESQUISAR',
    OTKWEB_VENDAS_NFE_PESQUISAR = 'OTKWEB_VENDAS_NFE_PESQUISAR',
    OTKWEB_VENDAS_NFSE_PESQUISAR = 'OTKWEB_VENDAS_NFSE_PESQUISAR',
    OTKWEB_VENDAS_ORDEM_PESQUISAR = 'OTKWEB_VENDAS_ORDEM_PESQUISAR',
    OTKWEB_VENDAS_PEDIDO_PESQUISAR = 'OTKWEB_VENDAS_PEDIDO_PESQUISAR',
    OTKWEB_VENDAS_ORDEM_SERVICOS = 'OTKWEB_VENDAS_ORDEM_SERVICOS',
    OTKWEB_VENDAS_PIS = 'OTKWEB_VENDAS_PIS',
    OTKWEB_VENDAS_VENDEDOR = 'OTKWEB_VENDAS_VENDEDOR',
    OTKWEB_COFINS = 'OTKWEB_COFINS',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID',
    OTKWEB_VENDAS_DISCRIMINACAO_SERVICOS = 'OTKWEB_VENDAS_DISCRIMINACAO_SERVICOS',
    OTKWEB_VENDAS_NOTA_FISCAL_PRODUTOS = 'OTKWEB_VENDAS_NOTA_FISCAL_PRODUTOS',
    OTKWEB_VENDAS_NOTA_FISCAL_SERVICOS = 'OTKWEB_VENDAS_NOTA_FISCAL_SERVICO',
    OTKWEB_ORDEM_COMPOSICAO_FINANCEIRA = 'OTKWEB_ORDEM_COMPOSICAO_FINANCEIRA',
    OTKWEB_COTACAO_COMPARACAO_V1 = 'OTKWEB_COTACAO_COMPARACAO_V1',
    OTKWEB_MAPA_DE_COTACOES = 'OTKWEB_MAPA_DE_COTACOES',
    OTKWEB_PEDIDO_DE_COMPRA_RATEIOS = 'OTKWEB_PEDIDO_DE_COMPRA_RATEIOS',
    OTKWEB_CRM_CLIENTES_ORDEM_DE_SERVICO = 'OTKWEB_CRM_CLIENTES_ORDEM_DE_SERVICO',
    OTKWEB_CRM_CLIENTES_PEDIDO_DE_VENDA = 'OTKWEB_CRM_CLIENTES_PEDIDO_DE_VENDA',
    OTKWEB_GRUPO_DE_CLIENTE = 'OTKWEB_GRUPO_DE_CLIENTE',
    OTKWEB_CRM_CLIENTES_FATURAS = 'OTKWEB_CRM_CLIENTES_FATURAS',
    OTKWEB_FINANCEIRO_EXTRATO = 'OTKWEB_FINANCEIRO_EXTRATO',
    OTKWEB_FINANCEIRO_EXTRATO_LANCAMENTOS = 'OTKWEB_FINANCEIRO_EXTRATO_LANCAMENTOS',
    OTKWEB_FINANCEIRO_EXTRATO_CRCP = 'OTKWEB_FINANCEIRO_EXTRATO_CRCP',
    OTKWEB_FINANCEIRO_PAGAMENTOS = 'OTKWEB_FINANCEIRO_PAGAMENTOS',
    OTKWEB_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA = 'OTKWEB_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA',
    OTKWEB_PRODUTO_PREVISAO_SALDO = 'OTKWEB_PRODUTO_PREVISAO_SALDO',
    OTKWEB_PRODUTO_MARGEM_LUCRO = 'OTKWEB_PRODUTO_MARGEM_LUCRO',
    OTKWEB_CONFIGURACAO_VENDAS = 'OTKWEB_CONFIGURACAO_VENDAS',
    OTKWEB_MAPEAMENTO_PADRAO_PRODUTOS = 'OTKWEB_MAPEAMENTO_PADRAO_PRODUTOS',
    OTKWEB_BRADESCO_QUESTIONARIO = 'OTKWEB_BRADESCO_QUESTIONARIO',
    OTKWEB_TABELA_IBPT = 'OTKWEB_TABELA_IBPT',
    OTKWEB_NOTA_FISCAL_PRODUTO_INUTILIZACAO = 'OTKWEB_NOTA_FISCAL_PRODUTO_INUTILIZACAO',
    OTKWEB_ORCAMENTOS_ABERTOS = 'OTKWEB_ORCAMENTOS_ABERTOS',
    OTKWEB_ORCAMENTOS_CANCELADOS = 'OTKWEB_ORCAMENTOS_CANCELADOS',
    OTKWEB_ORCAMENTOS_PROCESSADOS = 'OTKWEB_ORCAMENTOS_PROCESSADOS',
    OTKWEB_ORCAMENTOS_PRODUTOS = 'OTKWEB_ORCAMENTOS_PRODUTOS',
    OTKWEB_NOTA_RECEBIDAS = 'OTKWEB_NOTA_RECEBIDAS',
    OTKWEB_NOTA_RECEBIDAS_PRODUTOS = 'OTKWEB_NOTA_RECEBIDAS_PRODUTOS',

    OTKWEB_INVENTARIO_CADASTROS = 'OTKWEB_INVENTARIO_CADASTROS',
    OTKWEB_INVENTARIO_ITENS = 'OTKWEB_INVENTARIO_ITENS',

    OTKWEB_CNAB_REMESSA_PENDENTE = 'OTKWEB_CNAB_REMESSA_PENDENTE',
    OTKWEB_CNAB_REMESSA_GERADA = 'OTKWEB_CNAB_REMESSA_GERADA',
    OTKWEB_CNAB_RETORNO = 'OTKWEB_CNAB_RETORNO',

    OTKWEB_NOTA_DESTINADAS = 'OTKWEB_NOTA_DESTINADAS',
    OTKWEB_NOTA_DESTINADAS_PRODUTOS = 'OTKWEB_NOTA_DESTINADAS_PRODUTOS',
}

export enum TutorialEnum {
    FORNECEDOR = 'https://ajuda.otk.com.br/kb/pt-br/article/183783/fornecedores-passo-a-passo-como-cadastrar?ticketId=&q=#h_4f8e9b46-4681-4b33-8d85-b3f4019a5a44',
}
