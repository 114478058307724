import { NgModule } from '@angular/core';

import { CadastrosAuthClienteService } from './cadastros-auth-cliente.service';
import { CadastrosAuthFornecedorService } from './cadastros-auth-fornecedor.service';
import { CadastrosAuthProdutoService } from './cadastros-auth-produto.service';

import { CadastrosAuthProjetoService } from './cadastros-auth-projeto.service';
import { CadastrosAuthColaboradorService } from './cadastros-auth-colaborador.service';
import { CadastrosAuthFilialService } from './cadastros-auth-filial.service';
import { CadastrosAuthCentroDeCustoService } from './cadastros-auth-ccusto.service';

import { CadastrosAuthOutrosService } from './cadastros-auth-outros.service';

import { CadastrosAuthService } from './cadastros-auth.service';

@NgModule({
    providers: [
        CadastrosAuthService,
        CadastrosAuthProdutoService,
        CadastrosAuthClienteService,
        CadastrosAuthFornecedorService,
        CadastrosAuthProjetoService,

        CadastrosAuthColaboradorService,
        CadastrosAuthFilialService,
        CadastrosAuthCentroDeCustoService,
        CadastrosAuthOutrosService,
    ],
})
export class CadastrosAuthModule {}
